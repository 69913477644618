@custom-media --desktop-xlarge screen and (min-width: 1300px);
@custom-media --desktop-large screen and (min-width: 1100px);
@custom-media --desktop screen and (min-width: 960px);
@custom-media --tablet screen and (min-width: 768px);

@custom-media --adaptive-tablet screen and (min-width: 768px);
@custom-media --adaptive-desktop screen and (min-width: 1280px);
@custom-media --adaptive-bigscreen screen and (min-width: 1440px);

@define-mixin body {
	font-size: 14px;
	line-height: 20px;
}

@define-mixin caption {
	font-size: 12px;
	line-height: 18px;
}

@define-mixin h1 {
	font-size: 24px;
	line-height: 40px;
	font-weight: normal;
}

@define-mixin h2 {
	font-size: 22px;
	line-height: 36px;
	font-weight: normal;
}

@define-mixin h3 {
	font-size: 20px;
	line-height: 32px;
	font-weight: normal;
}

@define-mixin h4 {
	font-size: 18px;
	line-height: 28px;
	font-weight: normal;
}

@define-mixin h5 {
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
}

@define-mixin text {
	font-size: 16px;
	line-height: 22px;
}

@define-mixin smallText {
	font-size: 14px;
	line-height: 20px;
}

@define-mixin link {
	color: var(--accent-fill-rest);
	text-decoration: none;
	cursor: pointer;

	svg {
		fill: var(--accent-fill-rest);
		vertical-align: middle;
	}

	&:hover {
		color: var(--accent-fill-hover);
		text-decoration: underline;
		text-decoration-thickness: 1px;

		svg {
			fill: var(--accent-fill-hover);
		}
	}
}

@define-mixin title {
	font-size: 28px;
	line-height: 36px;
	font-weight: normal;
}

@define-mixin subtitle {
	font-size: 20px;
	line-height: 32px;
	font-weight: 600;
}

/* Cards */
@define-mixin shadowDepth1 {
	color: var(--neutral-foreground-rest);
	background-color: var(--neutral-fill-rest);
	padding: calc(var(--design-unit) * 4px);
	border: 1px solid var(--neutral-stroke-rest);
	border-radius: 8px;
	box-shadow: 0 0.3px 0.9px var(--shadow10), 0 1.6px 3.6px var(--shadow13);
}

/* Dropdowns */
@define-mixin shadowDepth2 {
	color: var(--neutral-foreground-rest);
	background-color: var(--neutral-fill-rest);
	padding: calc(var(--design-unit) * 4px);
	border: 1px solid var(--neutral-stroke-rest);
	border-radius: 8px;
	box-shadow: 0 0.6px 1.8px var(--shadow10), 0 3.2px 7.2px var(--shadow13);
}

/* Tooltips, hover cards */
@define-mixin shadowDepth3 {
	color: var(--neutral-foreground-rest);
	background-color: var(--neutral-fill-rest);
	padding: calc(var(--design-unit) * 4px);
	border: 1px solid var(--neutral-stroke-rest);
	border-radius: 8px;
	box-shadow: 0 1.2px 3.6px var(--shadow10), 0 6.4px 14.4px var(--shadow13);
}

/* Modals, panels */
@define-mixin shadowDepth4 {
	color: var(--neutral-foreground-rest);
	background-color: var(--neutral-fill-rest);
	padding: calc(var(--design-unit) * 4px);
	border: 1px solid var(--neutral-stroke-rest);
	border-radius: 8px;
	box-shadow: 0 4.8px 14.4px var(--shadow18), 0 25.6px 57.6px var(--shadow22);
}

@define-mixin insetFocus {
	outline: calc(var(--focus-stroke-width) * 1px) solid var(--neutral-foreground-rest);
	box-shadow: 0 0 0 calc((var(--focus-stroke-width) - var(--stroke-width)) * 1px) var(--focus-stroke-outer) inset,
		0 0 0 calc(((var(--focus-stroke-width) + var(--stroke-width)) - var(--stroke-width)) * 1px)
			var(--focus-stroke-inner) inset !important;
	border-radius: calc(var(--control-corner-radius) * 1px);
}

@define-mixin normalVerticalItem {
	&::before {
		content: "";
		background: transparent;
		display: block;
		width: 3px;
		border-radius: 2px;
		position: absolute;
		right: auto;
		left: 0;
		top: 12px;
		bottom: 12px;
		transition: top 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			bottom 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
	}
}

@define-mixin selectedVerticalItem {
	&::before {
		background: var(--accent-fill-rest);
		top: 8px;
		bottom: 8px;
	}
}

@define-mixin normalHorizontalItem {
	&::before {
		content: "";
		background-color: transparent;
		display: block;
		height: 3px;
		border-radius: 2px;
		position: absolute;
		top: auto;
		bottom: 0;
		width: 0;
		margin: 0 auto;
		transition: width 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
	}
}

@define-mixin clearHorizontalItem {
	&::before {
		height: auto;
		top: auto;
		bottom: auto;
		width: auto;
		margin: 0;
	}
}

@define-mixin selectedHorizontalItem {
	&::before {
		background-color: var(--accent-fill-rest);
		width: 15px;
	}
}

@define-mixin activeItem {
	&::before {
		background: var(--accent-fill-rest);
	}
}
/* stylelint-disable */
