@import "../guideline.css";

.toastContainer {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 12px;
	width: 100%;
	padding: 0 12px;
	z-index: 1000000;
	pointer-events: none;
}

.toast {
	display: flex;
	align-items: center;
	gap: 12px;
	border-width: 1px;
	border-style: solid;
	padding: 12px;
	border-radius: 6px;
	margin-bottom: 12px;
	max-width: 100%;
	pointer-events: auto;
	user-select: none;
	@mixin shadowDepth3;
	color: black;

	.close {
		cursor: pointer;
	}

	.content {
		word-break: break-word;
	}

	&.error {
		background: var(--color-error-background);
		border-color: var(--color-error);

		.icon svg {
			fill: var(--color-error);
		}
	}

	&.info {
		background: var(--color-secondary);
		border-color: var(--color-secondary-line);

		.icon svg {
			fill: var(--color-light-font-secondary);
		}
	}

	&.success {
		background: var(--color-success-background);
		border-color: var(--color-success);

		.icon svg {
			fill: var(--color-success);
		}
	}

	&.warning {
		background: var(--color-warning-background);
		border-color: var(--color-warning);

		.icon svg {
			fill: var(--color-warning);
		}
	}
}
