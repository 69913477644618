:root {
	color-scheme: light;

	/* Прозрачность для недоступных элементов */
	--disabled-opacity: 0.3;
	/* Базовый единица для расчета размеров и отступов */
	--design-unit: 4;
	/* Скругление для контролов */
	--control-corner-radius: 6;
	/* Скругление для элементов дизайна */
	--layer-corner-radius: 8;
	/* Толщина линий */
	--stroke-width: 1;
	/* Толщина для фокуса */
	--focus-stroke-width: 2;
	/* Базовая единица для расчета высоты */
	--base-height-multiplier: 8;
	/* Базовая величина z-index для кнопок в fields */
	--field-action-buttons-z-index: 5;
	/* Базовая величина z-index для модальных окон */
	--base-modal-z-index: 1200;

	--input-size: calc((var(--base-height-multiplier) * var(--design-unit) / 2) + var(--design-unit));

	--focus-stroke-inner: #ffffff;
	--focus-stroke-outer: #000000;

	/* accent */
	--accent-fill-rest: #0078d4;
	--accent-fill-hover: #006cbe;
	--accent-fill-active: #1683d8;
	--accent-fill-selected: #005393;
	--accent-foreground-rest: #ffffff;
	--accent-foreground-hover: #ffffff;
	--accent-foreground-active: #ffffff;
	--accent-foreground-secondary-rest: #eee;
	--accent-foreground-secondary-hover: #eee;
	--accent-foreground-secondary-active: #eee;
	--accent-stroke-control-rest: linear-gradient(#1b5e95 90%, #032b4b 100%);
	--accent-stroke-control-hover: linear-gradient(#1a639b 90%, #043559 100%);
	--accent-stroke-control-active: #1b6ea9;

	/* Фон основного меню */
	--body-fill-rest: #f0f3f9;
	--body-fill-hover: #eaeaea;
	--body-fill-active: #eaedf3;
	--body-stroke-rest: #e2e5ea;
	--body-foreground-rest: #191a1a;
	--body-foreground-hover: #181919;
	--body-foreground-active: #595b5d;
	--body-foreground-secondary-rest: #b0b2b7;

	/* Фон контента */
	--body-fill-secondary-rest: #f8f9fc;
	--body-fill-secondary-hover: #e9e9e9;
	--body-fill-secondary-active: #e5e5e5;

	/* Фон белых кнопок */
	--neutral-fill-rest: #fbfbfb;
	--neutral-fill-hover: #f6f6f6;
	--neutral-fill-active: #f5f5f5;

	--neutral-fill-alt-rest: #ededed;
	--neutral-fill-alt-hover: #f4f4f4;
	--neutral-fill-alt-active: #f4f4f4;

	--neutral-fill-secondary-rest: #efefef;
	--neutral-fill-secondary-hover: #f3f3f3;
	--neutral-fill-secondary-active: #f7f7f7;
	--neutral-fill-secondary-hint: #a19f9d;

	--neutral-foreground-rest: #1a1a1a;
	--neutral-foreground-hover: #7a7a7a;
	--neutral-foreground-active: #a8a8a8;
	--neutral-foreground-hint: #717171;

	--neutral-stroke-rest: #d6d6d6;
	--neutral-stroke-hover: #c6c6c6;
	--neutral-stroke-active: #e0e0e0;

	--neutral-stroke-alt-rest: #636363;
	--neutral-stroke-alt-hover: #636363;
	--neutral-stroke-alt-active: #bfbfbf;

	--neutral-stroke-control-rest: linear-gradient(#efefef 90%, #d6d6d6 100%);
	--neutral-stroke-control-hover: linear-gradient(#e5e5e5 90%, #cecece 100%);
	--neutral-stroke-control-active: #e5e5e5;

	--neutral-stroke-input-rest: linear-gradient(#efefef calc(100% - 1px), #929292 calc(100% - 1px), #929292);
	--neutral-stroke-input-hover: linear-gradient(#e5e5e5 calc(100% - 1px), #8a8a8a calc(100% - 1px), #8a8a8a);
	--neutral-stroke-input-active: #e5e5e5;

	--error-foreground: #a80000;
	--error-fill: #fde7e9;
	--warning-foreground: #c1a700;
	--warning-fill: #fff4ce;
	--success-foreground: #107c10;
	--success-fill: #dff6dd;

	--shadow10: rgba(0, 0, 0, 0.1);
	--shadow13: rgba(0, 0, 0, 0.13);
	--shadow18: rgba(0, 0, 0, 0.18);
	--shadow22: rgba(0, 0, 0, 0.22);
}

html {
	color-scheme: light;
}

@media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}

	:root {
		color-scheme: dark;
		--focus-stroke-inner: #ffffff;
		--focus-stroke-outer: #000000;

		/* accent */
		--accent-fill-rest: #006cbe;
		--accent-fill-hover: #0078d4;
		--accent-fill-active: #005ca3;
		--accent-fill-selected: #238ada;
		--accent-foreground-rest: #ffffff;
		--accent-foreground-hover: #ffffff;
		--accent-foreground-active: #ffffff;
		--accent-foreground-secondary-rest: #eee;
		--accent-foreground-secondary-hover: #eee;
		--accent-foreground-secondary-active: #eee;
		--accent-stroke-control-rest: linear-gradient(#1b5e95 90%, #032b4b 100%);
		--accent-stroke-control-hover: linear-gradient(#1a639b 90%, #043559 100%);
		--accent-stroke-control-active: #1b6ea9;

		--body-fill-rest: #1a202f;
		--body-fill-hover: #292d35;
		--body-fill-active: #25292f;
		--body-fill-alt-rest: #4d4d4d;
		--body-stroke-rest: #171d2b;
		--body-foreground-rest: #ffffff;
		--body-foreground-hover: #ffffff;
		--body-foreground-active: #cdced0;
		--body-foreground-secondary-rest: #b0b2b7;

		/* Фон контента */
		--body-fill-secondary-rest: #232733;
		--body-fill-secondary-hover: #343434;
		--body-fill-secondary-active: #e5e5e5;

		--neutral-fill-rest: #363636;
		--neutral-fill-hover: #3d3d3d;
		--neutral-fill-active: #313131;

		--neutral-fill-alt-rest: #2e323c;
		--neutral-fill-alt-hover: #3a3e48;
		--neutral-fill-alt-active: #373a45;

		--neutral-fill-secondary-rest: #242424;
		--neutral-fill-secondary-hover: #313131;
		--neutral-fill-secondary-active: #272727;
		--neutral-fill-secondary-hint: #363636;

		--neutral-foreground-rest: #f5f5f5;
		--neutral-foreground-hover: #7a7a7a;
		--neutral-foreground-active: #a8a8a8;
		--neutral-foreground-hint: #717171;

		--neutral-stroke-rest: #8f8f8f;
		--neutral-stroke-hover: #c6c6c6;
		--neutral-stroke-active: #e0e0e0;

		--neutral-stroke-alt-rest: #636363;
		--neutral-stroke-alt-hover: #636363;
		--neutral-stroke-alt-active: #bfbfbf;

		--neutral-stroke-control-rest: linear-gradient(#efefef 90%, #d6d6d6 100%);
		--neutral-stroke-control-hover: linear-gradient(#e5e5e5 90%, #cecece 100%);
		--neutral-stroke-control-active: #e5e5e5;

		--neutral-stroke-input-rest: linear-gradient(#505050 calc(100% - 1px), #b6b6b6 calc(100% - 1px), #b6b6b6);
		--neutral-stroke-input-hover: linear-gradient(#505050 calc(100% - 1px), #b6b6b6 calc(100% - 1px), #b6b6b6);
		--neutral-stroke-input-active: #e5e5e5;

		--error-foreground: #a80000;
		--error-fill: #fde7e9;
		--warning-foreground: #c1a700;
		--warning-fill: #fff4ce;
		--success-foreground: #107c10;
		--success-fill: #dff6dd;

		--color-shadow10: rgba(0, 0, 0, 0.3);
		--color-shadow13: rgba(0, 0, 0, 0.33);
		--color-shadow18: rgba(0, 0, 0, 0.38);
		--color-shadow22: rgba(0, 0, 0, 0.42);
	}
}
